import PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./styles.module.css"


const BusinessCard = ({ title, description, icon }) => {
  return (
    <div className={styles.featureCard}>
      <div className={styles.front}>
        <img src={icon} loading="eager" alt={title} className={styles.icon} />
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className={styles.back}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.separator}></div>
        <ReactMarkdown className={styles.description} source={description} />
      </div>
    </div>
  )
}

BusinessCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
}

BusinessCard.defaultProps = {
  type: "dark",
}

export default BusinessCard
