import React from "react"
import { StaticQuery, graphql } from "gatsby"

import POSPageComponent from "../page-components/POSPageComponent"

const imageSrcFormatter = (imageSrc, type = "fixed") =>
  (imageSrc &&
    imageSrc.childImageSharp &&
    imageSrc.childImageSharp[type] &&
    imageSrc.childImageSharp[type].src) ||
  ""

const query = graphql`
  query {
    page: markdownRemark(fields: { slug: { eq: "/pages/pos/" } }) {
      frontmatter {
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        title
        featuredImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        integrations {
          name
          icon {
            childImageSharp {
              fixed(height: 60, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        highlights {
          title
          description
          icon {
            childImageSharp {
              fixed(height: 60, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        featuresSections {
          title
          label
          description
          buttons {
            buttonText
            buttonUrl
            image {
            childImageSharp {
              fixed(height: 80, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          }
          image {
            childImageSharp {
              fixed(height: 300, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          icon {
            childImageSharp {
              fixed(height: 60, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          iconActive {
            childImageSharp {
              fixed(height: 60, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        cloudwareBusinessImageTextSection {
          text
          image {
            childImageSharp {
              fixed(height: 500, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        productCharacteristicsSection {
          title
          subtitle
          characteristics {
            title
            description
            icon {
              childImageSharp {
                fixed(height: 60, quality: 92) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        howDoesItWorkSection {
          title
          subtitle
          item {
            title
            description
            icon {
              childImageSharp {
                fixed(height: 60, quality: 92) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        pricingSection {
          title
          # subtitle
          notes
          subscriptionPlans {
            title
            price
          }
          items {
            name
            available
          }
          buttonsTitle
        }
        webinarsSection {
          title
          text
          date
          hours
          buttonText
          buttonUrl
          backgroundImage {
            childImageSharp {
              fluid(maxHeight: 450, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bannerSection {
          title
          highlight
          text
          image {
            childImageSharp {
              fixed(height: 300, quality: 92) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          urlBanner {
            text
            url
          }
        }
      }
    }
  }
`

const POSPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const {
        seoTitle,
        seoDescription,
        seoImage,
        title,
        subtitle,
        featuredImage,
        integrations,
        highlights,
        featuresSections,
        productCharacteristicsSection,
        howDoesItWorkSection,
        pricingSection,
        bannerSection,
        webinarsSection,
        cloudwareBusinessImageTextSection,
      } = data.page.frontmatter

      return (
        <POSPageComponent
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={imageSrcFormatter(seoImage)}
          title={title}
          subtitle={subtitle}
          featuredImage={imageSrcFormatter(featuredImage)}
          integrations={integrations}
          highlights={highlights}
          featuresSections={featuresSections}
          productCharacteristicsSection={productCharacteristicsSection}
          howDoesItWorkSection={howDoesItWorkSection}
          pricingSection={pricingSection}
          webinarsSection={webinarsSection}
          bannerSection={bannerSection}
          cloudwareBusinessImageTextSection={cloudwareBusinessImageTextSection}
          {...props}
        />
      )
    }}
  />
)

export default POSPage
