import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import SimpleLayout from "../layouts/SimpleLayout"
import SEO from "../components/seo"
import BusinessCard from "../components/BusinessCard/BusinessCard"
import HowDoesItWorkCard from "../components/HowDoesItWorkCard/HowDoesItWorkCard"
import POSSlider from "../components/POSSlider/POSSlider"
import POSContact from "../components/Forms/POSContact"
import POSPricing from "../components/POSPricing/POSPricing"
import styles from "./styles/pos.module.css"
/*
const report = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag("js", new Date())
  gtag("config", "AW-953576842")
}*/

const POSPageComponent = ({
  seoTitle,
  seoDescription,
  seoImage,
  title,
  subtitle,
  featuredImage,
  integrations,
  highlights,
  featuresSections,
  productCharacteristicsSection,
  howDoesItWorkSection,
  pricingSection,
  bannerSection,
  cloudwareBusinessImageTextSection,
  isPreview,
  webinarsSection,
  ...props
}) => (
  <SimpleLayout path={props.path} isPreview={isPreview}>
    <SEO
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      url="https://cloudware.pt/pos"
    />
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.col}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.row}>
              <a
                href="https://www.pos.cloudware.pt/criar-conta/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => {
                  window.gtag("event", "conversion", {
                    send_to: "AW-953576842/EEOqCJD3h-UBEIrb2cYD",
                    /*event_callback: callback,*/
                  })
                }}
              >
                <button className={styles.button}>Experimente grátis</button>
              </a>
              <a
                href="http://ajuda-pos.cloudware.pt/support/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className={`${styles.button} ${styles.transparent}`}>
                  Centro de ajuda
                </button>
              </a>
            </div>
          </div>
          <img
            className={styles.featuredImage}
            src={featuredImage}
            alt={title}
            loading="eager"
          />
        </div>
      </div>
    </div>

    <img
      src={require("../img/separator.png")}
      alt=""
      className={styles.separator}
    />

    <section className={styles.partners}>
      <div className={styles.content}>
        <p className={styles.text}>Integra com:</p>
        <div className={styles.row}>
          {integrations.map(partner => (
            <img
              className={styles.partnerIcon}
              src={partner.icon.childImageSharp.fixed.src}
              alt={partner.name}
              loading="eager"
            />
          ))}
        </div>
      </div>
    </section>

    <section className={styles.highlights}>
      <div className={styles.content}>
        <div className={styles.row}>
          {highlights.map(hl => (
            <div className={styles.highlightCard} key={hl.title}>
              <div className={styles.iconContainer}>
                <img
                  src={hl.icon.childImageSharp.fixed.src}
                  alt={hl.title}
                  loading="eager"
                />
              </div>

              <h1 className={styles.title}>{hl.title}</h1>
              <ReactMarkdown
                className={styles.description}
                source={hl.description}
              />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className={styles.featuresSections}>
      <div className={styles.background} />
      <div className={styles.content}>
        <POSSlider slides={featuresSections} />
      </div>
    </section>

    <section className={styles.productCharacteristicsSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>{productCharacteristicsSection.title}</h2>
        <h3 className={styles.subtitle}>
          {productCharacteristicsSection.subtitle}
        </h3>
        <div className={styles.row}>
          {productCharacteristicsSection.characteristics.map(
            ({ title, description, icon }) => (
              <BusinessCard
                title={title}
                description={description}
                icon={icon.childImageSharp.fixed.src}
              />
            )
          )}
        </div>
      </div>
    </section>

    <section className={styles.howDoesItWorkSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>{howDoesItWorkSection.title}</h2>
        <h3 className={styles.subtitle}>{howDoesItWorkSection.subtitle}</h3>
        <div className={styles.row}>
          {howDoesItWorkSection.item.map(({ title, description, icon }) => (
            <HowDoesItWorkCard
              title={title}
              description={description}
              icon={icon.childImageSharp.fixed.src}
            />
          ))}
        </div>
      </div>
    </section>

    <section
      className={styles.webinarsSection}
      style={{
        backgroundImage: `url(${webinarsSection.backgroundImage.childImageSharp.fluid.src})`,
      }}
    >
      <div className={styles.overlay} />
      <div className={styles.content}>
        <h2 className={styles.title}>{webinarsSection.title}</h2>
        <div className={styles.text}>
          <ReactMarkdown
            className={styles.text}
            style={{paddingBottom: 30}}
            source={webinarsSection.text}
          />
        </div>

        {webinarsSection.buttonUrl && (
          <a href={webinarsSection.buttonUrl} target="_blank">
            <button className={styles.gradientButton}>
              {webinarsSection.buttonText}
            </button>
          </a>
        )}
      </div>
      {(!!webinarsSection.hours || !!webinarsSection.date) && (
        <div className={styles.dateTime}>
          <div className={styles.overlay} />
          {!!webinarsSection.hours && (
            <div className={styles.time}>
              <img
                src={require("../img/clock copy.png")}
                className={styles.icon}
              />
              {webinarsSection.hours}
            </div>
          )}
          {!!webinarsSection.date && (
            <div className={styles.date}>
              <img
                src={require("../img/calendar copy.png")}
                className={styles.icon}
              />
              {webinarsSection.date}
            </div>
          )}
        </div>
      )}
    </section>

    <section className={styles.pricingSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>{pricingSection.title}</h2>
        <POSPricing
          subscriptionPlans={pricingSection.subscriptionPlans}
          items={pricingSection.items}
        />
        <ReactMarkdown className={styles.notes} source={pricingSection.notes} />
        <div className={styles.buttons}>
          <h3 className={styles.sectionTitle}>
            {pricingSection.buttonsTitle || "30 Dias Gratuitos Sem Compromisso"}
          </h3>
          <div className={styles.row}>
            <a
              href="https://www.pos.cloudware.pt/criar-conta/"
              target={"_blank"}
              rel="noopener noreferrer"
              onClick={e => {
                window.gtag("event", "conversion", {
                  send_to: "AW-953576842/EEOqCJD3h-UBEIrb2cYD",
                  /*event_callback: callback,*/
                })
              }}
            >
              <button className={styles.gradientButton}>
                Experimente grátis
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className={styles.cloudwareBusinessSection}>
      <div className={styles.background} />
      <div className={styles.content}>
        <div className={styles.row}>
          <img
            src={
              cloudwareBusinessImageTextSection.image.childImageSharp.fixed.src
            }
            alt="Cloudware Business"
            loading="eager"
          />
          <ReactMarkdown
            className={styles.text}
            source={cloudwareBusinessImageTextSection.text}
          />
        </div>
      </div>
    </section>

    <section className={styles.bannerSection}>
      {bannerSection.urlBanner && bannerSection.urlBanner.text && (
        <a
          href={bannerSection.urlBanner.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.urlBanner}
        >
          {bannerSection.urlBanner.text}
        </a>
      )}
      <div className={styles.content}>
        <h2 className={styles.title}>{bannerSection.title}</h2>
        <div className={styles.row}>
          <img
            className={styles.featuredImage}
            src={bannerSection.image.childImageSharp.fixed.src}
            loading="eager"
            alt={bannerSection.title}
          />
          <div className={styles.col}>
            <h3 className={styles.highlight}>{bannerSection.highlight}</h3>
            <ReactMarkdown
              className={styles.text}
              source={bannerSection.text}
            />
          </div>
        </div>
      </div>
    </section>

    <section className={styles.formSection} id="contact-form">
      <div className={styles.content}>
        <POSContact />
      </div>
    </section>
  </SimpleLayout>
)

export default POSPageComponent
