import React from "react"
import ReactMarkdown from "react-markdown"

import styles from "./styles.module.css"

const Slide = ({ slide }) => {
  return (
    <div className={styles.slide}>
      <img
        className={styles.slideImage}
        src={slide.image.childImageSharp.fixed.src}
        alt={slide.title}
        loading="eager"
      />
      {slide.buttons && (
        <div className={styles.buttons}>
          {slide.buttons.map(button => (
            <a href={button.buttonUrl} target="_blank">
              {button.image ? (
                <img
                  src={button.image.childImageSharp.fixed.src}
                  className={styles.buttonImage}
                />
              ) : (
                <button className={styles.button}>
                  {button.buttonText}
                </button>
              )}
            </a>
          ))}
        </div>
      )}
      <h2 className={styles.title}>{slide.title}</h2>
      <ReactMarkdown
        className={styles.description}
        source={slide.description}
      />
    </div>
  )
}

export default Slide
