import React, { useState, useRef } from "react"
import Slider from "react-slick"
import { isMobile } from "react-device-detect"
import Slide from "./Slide"

import styles from "./styles.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const POSSlider = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [count, updateCount] = useState(0)

  const slider = useRef()

  const settings = {
    dots: isMobile ? true : false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => updateCount(count + 1),
    beforeChange: (current, next) => setSlideIndex(next),
  }

  return (
    <div className={styles.slider}>
      <Slider ref={slider} {...settings}>
        {slides.map(slide => (
          <Slide slide={slide} key={slide.label} />
        ))}
      </Slider>
      <div className={styles.sliderController}>
        {slides.map((slide, i) => (
          /* eslint-disable-next-line */
          <div
            className={`${styles.button} ${
              slideIndex === i ? styles.active : ""
            }`}
            key={slide.label}
            onClick={() => slider.current && slider.current.slickGoTo(i)}
          >
            <img
              src={
                slideIndex === i
                  ? slide.iconActive.childImageSharp.fixed.src
                  : slide.icon.childImageSharp.fixed.src
              }
              alt={slide.label}
              loading="eager"
            />
            <h2 className={styles.label}>{slide.label}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default POSSlider
