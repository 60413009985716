import PropTypes from "prop-types"
import React from "react"

import styles from "./styles.module.css"

const HowDoesItWorkCard = ({ title, description, icon }) => {
  return (
    <div className={styles.featureCard}>
      <img src={icon} loading="eager" alt={title} className={styles.icon} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  )
}

HowDoesItWorkCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
}

HowDoesItWorkCard.defaultProps = {
  type: "dark",
}

export default HowDoesItWorkCard
