import React from "react"
import { Link } from "gatsby"

import styles from "./styles.module.css"

const POSPricing = ({ subscriptionPlans, items }) => {
  return (
    <div className={styles.pricing}>
      <div className={styles.col}>
        {items.slice(0, items.length / 2).map(item => (
          <div
            className={`${styles.item} ${
              item.available ? styles.available : ""
            }`}
          >
            <img
              className={styles.icon}
              src={require(`../../img/${
                item.available ? "check" : "cross"
              }.png`)}
              alt=""
            />
            {item.name}
          </div>
        ))}
      </div>
      <div className={styles.col}>
        {items.slice(items.length / 2, items.length).map(item => (
          <div
            key={item.name}
            className={`${styles.item} ${
              item.available ? styles.available : ""
            }`}
          >
            <img
              className={styles.icon}
              src={require(`../../img/${
                item.available ? "check" : "cross"
              }.png`)}
              alt=""
            />
            {item.name}
          </div>
        ))}
      </div>
      <div className={styles.buttonsRow}>
        {subscriptionPlans.map(pp => (
          <Link
            key={pp.title}
            to={`/pos-subscrever?plan=${pp.title
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(" ", "_")}`}
            className={`${styles.button} ${
              pp.title === "SUBSCRIÇÃO ANUAL" ? styles.active : ""
            }`}
          >
            <h3 className={styles.title}>{pp.title}</h3>
            <p className={styles.price}>{pp.price}€ / mês</p>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default POSPricing
