import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"

import { submitPOSContactForm } from "../../forms"
import TermsAndConditions from "./components/TermsAndConditions"
import DistrictList from "./components/DistricList"

import styles from "./styles/pos-contact.module.css"

const POSContact = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues: { district: "" },
  })

  const districtValue = watch("district", "")

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        reset()
        setSuccess(false)
      }, 10000)
    }
    /*eslint-disable-next-line*/
  }, [success])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
        reset()
      }, 10000)
    }
    /*eslint-disable-next-line*/
  }, [error])

  const onSubmit = async data => {
    setLoading(true)

    try {
      await submitPOSContactForm(data)
      setSuccess(true)
    } catch (err) {
      setError({ error: err })
    }
    setLoading(false)
  }

  const SuccessMessage = () => (
    <div
      className={styles.success}
      style={success ? { opacity: 1 } : { opacity: 0, zIndex: -1 }}
    >
      <p>O seu registo foi submetido com sucesso!</p>
      <p>Entraremos em contacto consigo em breve.</p>
      <p>Obrigado e até já!</p>
    </div>
  )

  const ErrorMessage = () => (
    <p className={styles.error}>
      Ocorreu um erro no envio da sua mensagem. Por favor, tente novamente mais
      tarde.
    </p>
  )
  return (
    <div className={styles.posContact}>
      <h2 className={styles.title}>Contacte-nos para saber mais informações</h2>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.inline}>
              <input
                className={`${errors.first_name ? styles.inputError : ""} ${
                  styles.half
                }`}
                name="first_name"
                placeholder="Primeiro Nome"
                ref={register({
                  required: "Por favor insira o seu primeiro nome",
                })}
              />
              <input
                className={`${errors.last_name ? styles.inputError : ""} ${
                  styles.half
                }`}
                name="last_name"
                placeholder="Último Nome"
                ref={register({
                  required: "Por favor insira o seu último nome",
                })}
              />
            </div>

            <input
              className={errors.email ? styles.inputError : ""}
              name="email"
              placeholder="Email"
              ref={register({
                required: "Por favor insira o seu email",
              })}
            />
            <input
              className={errors.phone ? styles.inputError : ""}
              name="phone"
              placeholder="Telemóvel"
              ref={register()}
            />
            <DistrictList
              style={{
                color: districtValue ? "#3f3f3f" : "#8e8e8e",
              }}
              styles={styles}
              name="district"
              ref={register({
                required: "Por favor insira o seu distrito",
              })}
              error={errors.district}
            />
          </div>
          <div className={styles.col}>
            <textarea
              className={errors.message ? styles.inputError : ""}
              name="message"
              placeholder="Mensagem"
              ref={register({
                required: "Por favor escreva uma mensagem",
              })}
            />
          </div>
        </div>

        <div className={styles.checkbox}>
          <input
            type="checkbox"
            name="accepted_terms"
            ref={register({
              required: "Por favor aceite os termos e condições",
            })}
          />
          <TermsAndConditions />
        </div>

        <button type="submit" className={styles.button}>
          {loading ? "A enviar..." : "Enviar"}
        </button>
        {Object.keys(errors).map(error => (
          <p className={styles.error} key={error}>
            {errors[error].message}
          </p>
        ))}
        {success && <SuccessMessage />}
        {error && <ErrorMessage />}
      </form>
    </div>
  )
}

export default POSContact
